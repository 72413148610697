<template>
  <div>
    <headers :viewParams="viewParams" />
    <div class="col-xl-3 cleft" >
        <div class="card-box pb-2 ribbon-box" style="    height: 1480px;
    overflow-y: auto;
    overflow-x: hidden;">
            <corptop100 />
        </div>
        <div class="card-box pb-2 ribbon-box" style="    height: 1480px;
    overflow-y: auto;
    overflow-x: hidden;">
            <corphot100 />
        </div>
    </div>
    <div class="content-page col-xl-9">
        <div class="content">

            <!-- Start Content-->
        <div class="container-fluid">
          <div class="row">
              <div class="col-12">
                <div id="progressbarwizard">

                    <ul class="nav nav-pills bg-light nav-justified form-wizard-header mb-3">
                        <li class="nav-item">
                            <a @click="change_tab('tab1')" data-toggle="tab" v-bind:class="[cur_tab=='tab1' ? 'nav-link rounded-0 pt-2 pb-2 active':'nav-link rounded-0 pt-2 pb-2']" >
                                <i class="mdi mdi-account-circle mr-1"></i>
                                <span class="d-none d-sm-inline"><div class="lang" v-html="lan.to_company"></div></span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a  @click="change_tab('tab2')" data-toggle="tab" v-bind:class="[cur_tab=='tab2' ? 'nav-link rounded-0 pt-2 pb-2 active':'nav-link rounded-0 pt-2 pb-2']">
                                <i class="mdi mdi-face-profile mr-1"></i>
                                <span class="d-none d-sm-inline"><div class="lang" v-html="lan.to_office"></div></span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a  @click="change_tab('tab3')" data-toggle="tab" v-bind:class="[cur_tab=='tab3' ? 'nav-link rounded-0 pt-2 pb-2 active':'nav-link rounded-0 pt-2 pb-2']">
                                <i class="mdi mdi-face-profile mr-1"></i>
                                <span class="d-none d-sm-inline"><div class="lang" v-html="lan.comparison_with_removable_properties"></div></span>
                            </a>
                        </li>                                                    
                        
                    </ul>
                
                    <div class="tab-content b-0 mb-0 pt-0">
                
                        <div id="bar" class="progress mb-7" style="height: 7px;display: none;">
                            <div class="bar progress-bar progress-bar-striped progress-bar-animated bg-success"></div>
                        </div>
                
                        <div v-bind:class="[cur_tab=='tab1' ? 'tab-pane active':'tab-pane']" id="tab1" v-if="cur_tab=='tab1'">
                            
                                <corp-List-Recommend />
                          
                        </div>

                        <div v-bind:class="[cur_tab=='tab2' ? 'tab-pane active':'tab-pane']" id="tab2" v-if="cur_tab=='tab2'">
                            
                                <h4 class="header-title">{{ paramName }}</h4>
                                  <div class="card-box">
                                        <list-Corp-table :viewParams="viewParamsOffice"  ref="list-Corp-table" :key="new Date().getTime()" />
                                      </div>
                            
                        </div>

                        <div v-bind:class="[cur_tab=='tab3' ? 'tab-pane active':'tab-pane']" id="tab3" v-if="cur_tab=='tab3'">
                       <div class="row">
                    <div class="col-xl-12">
                        <div class="card-box">
                            <h4 class="header-title mb-4"><div class="lang" v-html="lan.reson_info"></div></h4>

                            <div class="row">
                                <div class="col-sm-3">
                                    <div class="nav flex-column nav-pills nav-pills-tab" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                        <a class="nav-link active show mb-1" id="v-pills-home-tab" data-toggle="pill"  role="tab" aria-controls="v-pills-home"
                                            aria-selected="true">
                                            築年数</a>
                                        <a class="nav-link mb-1" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile"
                                            aria-selected="false">
                                            賃料相場</a>
                                        <a class="nav-link mb-1" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages"
                                            aria-selected="false">
                                            アクセス</a>
                                        <a class="nav-link mb-1" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings"
                                            aria-selected="false">
                                            オフィスグレード</a>
                                    </div>
                                </div> <!-- end col-->
                                <div class="col-sm-9">
                                    <div class="tab-content pt-0">
                                        <div class="tab-pane fade active show" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                            <p>
                                                現物件の築年数：${} <br>お勧め物件の築年数：${}
                                            </p>
                                        </div>
                                        <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                            <p>
                                                現物件の賃料：${}（円/坪）<br>お勧め物件の賃料：${}（円/坪）
                                            </p>
                                        </div>
                                        <div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                            <p>
                                                現物件アクセス：${} <br>お勧め物件のアクセス：${}
                                            </p>
                                        </div>
                                        <div class="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                            <p>
                                                オフィスグレード：${} <br>オフィスグレード：${}
                                            </p>
                                        </div>
                                    </div>
                                </div> <!-- end col-->
                            </div> <!-- end row-->
                            
                        </div> <!-- end card-box-->
                    </div> <!-- end col -->
                </div>
                <div class="row">
                    <div class="col-xl-6">
                        <div class="card-box pb-2">
                            <h3 class="header-title mb-3">移転元</h3>
                            <div class="embed-responsive dash embed-responsive-16by9">
                                <iframe class="embed-responsive-item" 
                                    src="https://cbredatacom.s3-ap-northeast-1.amazonaws.com/properties/radarchart/8817.html">
                                </iframe>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div class="card-box pb-2">
                            <h3 class="header-title mb-3">移転先</h3>
                            <div class="embed-responsive dash embed-responsive-16by9">
                                <iframe class="embed-responsive-item" 
                                    src="https://cbredatacom.s3-ap-northeast-1.amazonaws.com/properties/radarchart/8817.html">
                                </iframe>
                            </div>
                        </div>
                    </div>
                </div> <!-- end row -->
                

                        </div>

                        <ul class="list-inline mb-0 wizard">
                            <li class="previous list-inline-item">
                                <a href="javascript: void(0);" @click="change_tab(cur_tab!='tab3' ?cur_tab!='tab2' ? 'tab1':'tab1':'tab2')" class="btn btn-secondary">Previous</a>
                            </li>
                            <li class="next list-inline-item float-right">
                                <a href="javascript: void(0);" @click="change_tab(cur_tab!='tab3' ?cur_tab!='tab2' ? 'tab2':'tab3':'tab3')" class="btn btn-secondary">Next</a>
                            </li>
                        </ul>

                    </div> <!-- tab-content -->
                </div> <!-- end #progressbarwizard-->
                
              </div>
            </div>

            <!-- Footer Start -->
                    <div class="container-fluid">
                        <div class="row">
                            <div>
                                <p>
                                    重要なお知らせ                                </p>
                    
                                <p>
                                    本レポートに含まれる資料に関する著作権等の権利は、別段の記載のない限り、CBREに属します。本レポートに含まれる情報は、予測に関するもの
                                    を含め、本レポートの発行時点で、信頼に足ると考えられる資料及び情報源から取得したものです。当社は、それらの情報の正確性について疑うべき事由を認識してはおりませんが、そ&gt;れらの情報に関する検証を行ってはおらず、また、それらの情報について表明、保証等を行うものではありません。本レポートの読者は、本レポートに含まれる情報の関連性、正確性及&gt;び完全性並びにそれら情報が最新のものであるか否かについて、独自に評価する責任があります。本レポートは、当社の顧客及び専門家のみを対象として、純粋な情報提供の目的でご提&gt;供するものであり、従って、本レポートは、有価証券若しくはその他の金融商品の売買若しくは取得の申込み又はその誘引を目的とするものではありません。これら資料に関する一切の&gt;権利は留保されており、これら資料、その内容、及び複製物のいずれについても、その方法の如何を問わず、書面による当社の事前の明示的な許可なく、変更し、送信し、複製し、又は&gt;他者に配布することはできません。許諾を受けずに当社の調査レポートを公表し、又は再配布することは禁止されています。当社は、何人に対しても、本レポートに含まれる情報を使用&gt;し、またはそれらの情報に依拠したことにより生じた損失、損害、費用及び経費について、一切責任を負いません。                                </p>
                            </div>
                        </div>
                    </div>
                    <footer class="footer">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-6">
                                2018 - 2021 © Empowered by  <a href="">CBRE</a> 
                            </div>

                            <div class="col-md-6">
                                <div class="text-md-right footer-links d-none d-sm-block">
                                    <a href="https://www.cbre.co.jp/ja-jp/about/japan-term-of-use">
                                        利用規則                                        
                                    </a>
                                    <a href="https://www.cbre.co.jp/ja-jp/about/japan-privacy-policy">
                                        利用規則                                        
                                    </a>
                                    <a href="https://support.cbredata.com/">
                                        ヘルプ                                            
                                    </a>
                                    <a href="mailto:APAC-JPN-D&amp;T_Research_TD&amp;L@cbre.com">
                                        お問合せメール                                            
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                    <!-- end Footer -->
          </div>
      </div>
    </div>
  </div>
</template>
<style>
.col-xl-3 ,.col-xl-9{ float:left;margin-left:0;margin-top: 95px;} .cleft .card-box{padding:0}
</style>
<script>

import ListCorpTable from '@/components/ListCorpTable.vue'
import Headers from '@/components/Headers.vue'
import Corphot100 from '@/views/Corphot100.vue'
import Corptop100 from '@/views/Corptop100.vue'
import CorpListRecommend from '@/views/CorpListRecommend.vue'
import router from '@/router'
import axios from 'axios';
import { inject } from 'vue';
export default {
  name: 'CorpDetail',
  components: {
    ListCorpTable,
    Corphot100,
    Corptop100,
    CorpListRecommend,
    Headers,
    
  },
  data() {
    const { currentRoute } = router
    return {
      cur_tab:currentRoute.value.query.cur_tab==undefined ? 'tab1':currentRoute.value.query.cur_tab,
      tab_url:{'tab1':'/corpRecommend?cur_tab=tab1','tab2':'/corpRecommend?cur_tab=tab2&dataId=1&dataType=1&dataName=%E3%82%AB%E3%83%AF%E3%82%BF&dataNumber=6120001042354&code=6292&hideHead=1&curindex=tc8','tab3':'/corpRecommend?cur_tab=tab3'},
      curindex:currentRoute.value.query.curindex==undefined || currentRoute.value.query.curindex==null || currentRoute.value.query.curindex=='' ? 'tc1': currentRoute.value.query.curindex,
      tolink:currentRoute.value.query.dataType==100 ? 'CorpReference': 'CorpList'+currentRoute.value.query.dataType,
      paramName:currentRoute.value.query.dataName,
      dataType:currentRoute.value.query.dataType,
      dataId:currentRoute.value.query.dataId,
      code:currentRoute.value.query.code,
      lan:inject('global').language_data,lan_help:inject('global').language_help_data,
      hideHead:currentRoute.value.query.hideHead==undefined ?0:currentRoute.value.query.hideHead,
      dataNumber:currentRoute.value.query.dataNumber==undefined ? "":currentRoute.value.query.dataNumber,
      
      viewParamsOffice: {
        name: 'corp_offices',
        apiUrl: '/v1/corpsoffices/' + currentRoute.value.query.dataId+'?cid='+ currentRoute.value.query.dataType+'&code='+ currentRoute.value.query.code+'&corp_name='+ currentRoute.value.query.dataName+'&corp_num=',
        paramKey: 'ID',
        randIndex:currentRoute.value.query.hideHead==undefined ?0:currentRoute.value.query.hideHead,
      },
     
    }
  },methods:{
    tab:function(tid){
      
      this.curindex=tid;
      this.$router.push((this.dataType==100 ?'/CorpReference/0':'/corp'+this.dataType+'/'+this.dataId)+'?dataId='+this.dataId+'&dataType='+this.dataType+'&dataName='+this.paramName+'&dataNumber='+this.dataNumber+'&code='+this.code+'&curindex='+tid)
    },
    change_tab(tab){
      console.log(tab+'<>'+this.cur_tab)
      if(tab!=this.cur_tab || tab=='tab1'){
        this.$router.push(this.tab_url[tab]);
      }
    },
    backListener () {
      this.$refs.myEnergy.destroySelf()
    },

  },
   mounted() {
     //挂载完成后，判断浏览器是否支持popstate
    if (window.history && window.history.pushState) {
      window.addEventListener('pagehide', this.backListener, false)
    }
   
     if(this.hideHead==1){
       this.curindex='tc8';
     }
     if(this.dataType==100){
      
      axios.get('/v1/corps'+ this.dataType+'/' + this.dataId+'?cid='+ this.dataType+'&code='+ this.code+'&corp_name='+ this.paramName,{params:{id_token:localStorage.getItem('id_token')}})
        .then(response => {
            this.dataId=response.data.ID;
            this.code = response.data.code!=undefined ? response.data.code:"";
            this.dataNumber=response.data.Corp_Num!=undefined ? response.data.Corp_Num: response.data.corp_num;
            this.viewParamsOffice.apiUrl='/v1/corpsoffices/' + this.dataId+'?cid='+ this.dataType+'&code='+ this.code+'&corp_name='+ this.paramName+'&corp_num='+ this.dataNumber;
          
        })
        .catch(error => (
            console.log(error),
            this.jsonErr = true,
            this.isLoading = false
          )
        );
     }
  },beforeUnmount () {
    window.removeEventListener('pagehide', this.backListener)
  },
  watch:{
  $route(){
    const { currentRoute } = router;
      this.cur_tab=currentRoute.value.query.cur_tab==undefined ? 'tab1':currentRoute.value.query.cur_tab;
      this.curindex=currentRoute.value.query.curindex==undefined || currentRoute.value.query.curindex==null || currentRoute.value.query.curindex=='' ? 'tc1': currentRoute.value.query.curindex;
      this.tolink=currentRoute.value.query.dataType==100 ? 'CorpReference': 'CorpList'+currentRoute.value.query.dataType;
      this.paramName=currentRoute.value.query.dataName;
      this.dataType=currentRoute.value.query.dataType;
      this.dataId=currentRoute.value.query.dataId;
      this.code=currentRoute.value.query.code;
      this.hideHead=currentRoute.value.query.hideHead==undefined ?0:currentRoute.value.query.hideHead;
      this.dataNumber=currentRoute.value.query.dataNumber==undefined ? "":currentRoute.value.query.dataNumber;
      
      this.viewParamsOffice= {
        name: 'corp_offices',
        apiUrl: '/v1/corpsoffices/' + currentRoute.value.query.dataId+'?cid='+ currentRoute.value.query.dataType+'&code='+ currentRoute.value.query.code+'&corp_name='+ currentRoute.value.query.dataName+'&corp_num=',
        paramKey: 'ID',
        randIndex:currentRoute.value.query.hideHead==undefined ?0:currentRoute.value.query.hideHead,
      };
      if(this.dataType==100 && this.cur_tab=='tab2'){
      
      axios.get('/v1/corps'+ this.dataType+'/' + this.dataId+'?cid='+ this.dataType+'&code='+ this.code+'&corp_name='+ this.paramName,{params:{id_token:localStorage.getItem('id_token')}})
        .then(response => {
            this.dataId=response.data.ID;
            this.code = response.data.code!=undefined ? response.data.code:"";
            this.dataNumber=response.data.Corp_Num!=undefined ? response.data.Corp_Num: response.data.corp_num;
            this.viewParamsOffice.apiUrl='/v1/corpsoffices/' + this.dataId+'?cid='+ this.dataType+'&code='+ this.code+'&corp_name='+ this.paramName+'&corp_num='+ this.dataNumber;
          
        })
        .catch(error => (
            console.log(error),
            this.jsonErr = true,
            this.isLoading = false
          )
        );
     }
  }
},

 
}
</script>
